import React, { Component } from 'react';
import Hole from './Hole';
import {
    Row,
    Col,
    Container,
    Input,
    Label
} from 'reactstrap';
import Select from 'react-select';
import {getDefaultBoxType, getDefaultBoxSide} from '../../box-helpers';
import Button from 'reactstrap/lib/Button';
import Alert from 'reactstrap/lib/Alert';

class HoleFormItems extends Component {
    boxSides = [
        {
            key: "A",
            label: "A (Face)"
        },
        {
            key: "B",
            label: "B (Top)"
        },
        {
            key: "C",
            label: "C (Left)"
        },
        {
            key: "D",
            label: "D (Bottom)"
        },
        {
            key: "E",
            label: "E (Right)"
        },
        {
            key: "Lid",
            label: "Lid"
        }
    ];
    state = {
        holeId: 0,
        boxType: getDefaultBoxType(),
        boxSide: getDefaultBoxSide(),
        diameter: 3,
        diameterColor: 'input-default',
        hasSizeError: false,
        positionX: 0,
        positionY: 0
    }
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.handleSideChange = this.handleSideChange.bind(this)
        this.state.holeId = props.hole.props.holeId;
        this.state.boxSide = props.hole.props.boxSide;
        this.state.diameter = props.hole.props.diameter;
        this.state.positionX = props.hole.props.positionX;
        this.state.positionY = props.hole.props.positionY;
    }

    handleDelete = (e) => {
        e.preventDefault();
        console.log(this.state.holeId)
        this.props.resetHoles(this.state.holeId);
        this.props.removeHoleFormItem(this.state.holeId);

    }

    handleChange = (e) => {
        e.preventDefault();
        // console.log("et", e.target.dataset.type);
        // console.log("e", e.target.value);

        this.props.resetHoles(this.state.holeId)

        if (e.target.dataset.type === "diameter" && e.target.value && e.target.value < 3) {
            this.setState({diameterColor: "input-danger", hasSizeError: true})
        } else {
            this.setState({diameterColor: "input-default", hasSizeError: false})
        }

        let dataType= e.target.dataset.type;
        let holeId= e.target.dataset.id;
        this.setState({ [dataType]: e.target.value }, () => {
            const hole = <Hole key={holeId} holeId={holeId} {...this.state} />
            // console.log("changehole", hole)
            this.props.addHole(hole);
        })

    }

    handleSideChange = (e) => {

        this.props.resetHoles(this.state.holeId)

        this.setState({boxSide: e.value}, () => {
            const hole = <Hole key={this.state.holeId} {...this.state} />
            // console.log("ehole", hole);
            this.props.addHole(hole);
        });

        this.props.hasLidChange(this.state.holeId, e.value, "hole");

    }

    render()  {
       const idx = this.props.idx;
       const key = this.props.holeKey;

       return (
        <Container key={"hole-wrapper-" + key}>

            <Row className="form-inline form-short">
                <Col className="col pr-0" md={3}>
                    <Label className="hole-label">Hole #{idx} -  Side</Label>
                </Col>
                <Col className="col pr-0" md={2}>
                    <Label className="hole-label">Diameter (mm)</Label>
                </Col>
                <Col className="col pr-0" md={3}>
                    <Label className="hole-label">X Position (mm)</Label>
                </Col>
                <Col className="col pr-0" md={3}>
                    <Label className="hole-label">Y Position (mm)</Label>
                </Col>
                <Col className="col pr-0" md={1}>
                </Col>

            </Row>
            <Row className="form-inline form-short">
                <Col className="col pr-0" md={3} style={{ minWidth: '18%'}}>
                    <Select
                        key={"holeSide"+idx}
                        id={"hole-side-"+idx}
                        name={"holeSide"+idx}
                        data-id={idx} data-type="boxSide"
                        isClearable={false}
                        value={{ value: this.state.boxSide, label: this.state.boxSide}}
                        onChange={this.handleSideChange}
                        options={this.boxSides.map((t) => ({ value: t.key, label: t.label }))}
                    />
                </Col>
                <Col className="col pr-0 pl-1" md={2} style={{ maxWidth: '22%'}}>
                    <Input value={this.state.diameter} onChange={this.handleChange} className={this.state.diameterColor} id={"hole-diameter-"+idx} key={"holeDiameter"+idx} name={"holeDiameter"+idx} data-type="diameter" type="number"  placeholder="Hole Diameter" inputMode="decimal" step="any" />
                </Col>
                <Col className="col pr-0" md={3}>
                    <Input value={this.state.positionX} onChange={this.handleChange}  id={"hole-xvalue-"+idx} key={"holePositionX"+idx} name={"holePositionX"+idx} data-type="positionX" type="number" placeholder="X Value" step="any" />
                </Col>
                <Col className="col pr-0" md={3}>
                    <Input value={this.state.positionY} onChange={this.handleChange}  id={"hole-xvalue-"+idx} key={"holePositionY"+idx} name={"holePositionY"+idx} data-type="positionY"  type="number" placeholder="Y Value" step="any" />
                </Col>
                <Col className="col pr-0" md={1}>
                    <Button onClick={this.handleDelete} color="danger">x</Button>
                </Col>

            </Row>
            <Row>
                <Col md={{size:6, offset:1 }}>
                    <Alert color="danger" isOpen={this.state.hasSizeError}>
                        Minimum Size is 3mm
                    </Alert>
                </Col>
            </Row>
        </Container>
       );
   }
}

export default HoleFormItems;